import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Toaster } from 'ngx-toast-notifications';
import { ApiService } from 'src/app/services/api.service';
import { GlobalConstants } from 'src/app/services/global';


@Component({
  selector: 'app-modal-manifest',
  templateUrl: './modal-manifest.component.html',
  styleUrls: ['./modal-manifest.component.scss']
})
export class ModalManifestComponent implements OnInit {


  title:any='Manifest'
  @Input() fromParent;
  dataList:any;
  global={...GlobalConstants.x}
  pgn={
    totalItems:0,
    itemsPerPage:0,
    limit:this.global.limitData,
    currentPage:1,
    prevPage:0,
    term:null
  };
  constructor(
    public activeModal:NgbActiveModal,
    private apiService:ApiService,
    private toast:Toaster
  ) { }

  ngOnInit() {
    
    this.loadPage(1);
  }

  loadPage(page: any) {
    if (page !== this.pgn.prevPage) {
      this.pgn.prevPage = page;
      this.pgn.currentPage=page;
      this.getData();
    }
  }

  pilihData(n:any){
    console.log('===>>>>>',n);
    this.activeModal.close(n.manifest_id);

  }

  async getData(){
    try {

      const res: any  = await this.apiService.ASSIGNMENT_MANIFEST.getManifestBerangkatOs(this.pgn.currentPage,this.pgn.limit,this.pgn.term,this.fromParent.hub?this.fromParent.hub:null);
      console.log(res);
      if(res && res.success==true){
        this.dataList = res.data;
        this.pgn.totalItems=res.total;
        this.pgn.itemsPerPage=res.limit;
      }
      } catch (error) {
        this.toast.open({type:'danger',text:error})
      }
  }

  showDataDetail(i:any){
    if (!this.dataList[i]['showDetail']){
      this.dataList[i]['showDetail']=true;
    }else{
      this.dataList[i]['showDetail']=false;
    }
  }


  closeModal(sendData) {

    this.activeModal.close({
      type:this.fromParent,
      data:sendData
    });
  }
}
