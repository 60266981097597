 
<form
class="form-data"
[formGroup]="validateForm"
(ngSubmit)="submitForm()">
<div class="mb-3 row">
  <label for="contact_id" class="col-md-2 col-form-label">Kode Customer</label>
  <div class="col-md-4">
    <input
      class="form-control"
      type="text"
      id="contact_id"
      formControlName="contact_id"
      placeholder="Otomatis"
    />
  </div>
</div>
<div class="mb-3 row">
  <label for="contact_reff" class="col-md-2 col-form-label">Kode Refferensi
  </label>
  <div class="col-md-4">
    <input
      type="text"
      class="form-control"
      id="contact_reff"
      formControlName="contact_reff"
      placeholder="Kode Reff"

      [ngClass]="{'is-invalid': submit && form.contact_reff.errors}"
    />
    <div *ngIf="submit && form.contact_reff.errors" class="invalid-feedback">
      <span *ngIf="form.contact_reff.errors.required">Mohon isi contact reff (ex : abc )</span>
    </div>
  </div>
  
</div>
<div class="mb-3 row">
  <label for="name" class="col-md-2 col-form-label">Nama Customer</label>
  <div class="col-md-9">
    <input
      class="form-control"
      type="text"
      placeholder="Nama Perusahaan/Perorangan"
      id="name"
      formControlName="name"
      [ngClass]="{'is-invalid': submit && form.name.errors}"
    />
    <div *ngIf="submit && form.name.errors" class="invalid-feedback">
      <span *ngIf="form.name.errors.required">Mohon isi nama customer (ex : abc )</span>
    </div>
  </div>
</div>
<!-- <div class="mb-3 row">
  <label for="email_addr" class="col-md-2 col-form-label">Email </label>
  <div class="col-md-6">
    <input
      type="email"
      formControlName="email"
      class="form-control"
      id="email_addr"
      name="email_addr"
      placeholder="Email "
      [ngClass]="{'is-invalid': submit && form.email.errors}"
    />
    <div *ngIf="submit && form.email.errors" class="invalid-feedback">
      <span *ngIf="form.email.errors.required">Mohon isi alamat email</span>
    </div>
  </div>
</div> -->
<div class="mb-3 row">
  <label for="phone1" class="col-md-2 col-form-label">Phone 1 </label>
  <div class="col-md-9">
    <input
      type="text"
      formControlName="phone1"
      class="form-control"
      id="phone1"
      placeholder="Telepon perusahaan/individu"
    />
  </div>
</div>
<!-- <div class="mb-3 row">
  <label for="phone2" class="col-md-2 col-form-label">Phone 2 </label>
  <div class="col-md-9">
    <input
      type="text"
      formControlName="phone2"
      class="form-control"
      id="phone2"
      name="cont_telp2"
      placeholder="Handphone "
    />
  </div>
</div> -->
<div class="mb-3 row">
  <label for="address1" class="col-md-2 col-form-label"
    >Alamat lengkap
  </label>
  <div class="col-sm-9">
    <textarea
      class="form-control"
      formControlName="address1"
      id="address1"
      name="address1"
      rows="4"
      placeholder="Alamat lengkap"
      [ngClass]="{'is-invalid': submit && form.address1.errors}"
    ></textarea>
    <div *ngIf="submit && form.address1.errors" class="invalid-feedback">
      <span *ngIf="form.address1.errors.required">Mohon isi alamat lengkap</span>
    </div>
  </div>
</div>
 
<div class="mb-3 row">
  <label for="city_code" class="col-md-2 col-form-label">Kota Asal</label>
  <div class="col-sm-3">
    <div class="input-group">
      <input
        type="text"
        class="form-control"
        formControlName="city_code"
        id="inputCityCode"
        aria-describedby="inputCityCode"
      /><button
        class="btn btn-primary"
        type="button"
        id="inputCityCode"
        (click)="openModalHub()"
      >
        <i class="fa fa-search"></i>
      </button>
    </div>
  </div>

  <div class="col-sm-6">
    <input
      type="text"
      formControlName="city_name"
      class="form-control"
      id="city_name"
      name="city_name"
      placeholder="Nama Hub"
    />
  </div>
  <!-- <app-master-hub (sendHubData)="receiveHubData($event)" ></app-master-hub> -->
</div>
<div class="btn-group float-end" role="group">
  <button type="button" class="btn btn-dark" *ngIf="!isEdit" (click)="batalForm()">Batal</button>
  <button type="submit" class="btn btn-success">Simpan</button>
</div>
</form>
