import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
const httpOptions = {
  headers: new HttpHeaders({ Accept: "application/json" }),
};


@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  constructor(private httpClient: HttpClient) {}

  public qsconvert(params: any) {
      return Object.keys(params)
        .map((key) => `${key}=${params[key]}`)
        .join("&");
    }

    public getDashboardShipment(cid: string) {
      return cid
        ? this.httpClient
            .get(
              `${environment.apiUrl}/dashboard/shipment-summary?filter=customer&contact_id=${cid}`
            )
            .toPromise()
        : this.httpClient
            .get(`${environment.apiUrl}/dashboard/shipment-summary`)
            .toPromise();
    }
    public getDashboardShipmentByPeriods(
      startDate: any = null,
      endDate: any = null,
      type:any
    ) {
      return this.httpClient
        .get(`${environment.apiUrl}/dashboard/shipment-summary-periode?type=${type}&start_date=${startDate}&end_date=${endDate}`)
        .toPromise();
    }

    public getDashboardShipmentByProvince(
      startDate: any = null,
      endDate: any = null,
      type:any
    ) {
      return this.httpClient
        .get(`${environment.apiUrl}/dashboard/shipment-summary-province?type=${type}&start_date=${startDate}&end_date=${endDate}`)
        .toPromise();
    }

}
