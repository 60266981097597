<div class="modal-header">
    <h5 class="modal-title mt-0">Daftar Kota</h5>
    <button type="button" class="btn-close" data-dismiss="modal" (click)="closeModal('dismiss')"><span aria-hidden="true"></span>
    </button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-6">
        </div>
        <div class="col-6">
            <div class="input-group mb-3">
                <input type="text" class="form-control" placeholder="Cari..." (keyup.enter)="getData()" aria-label="Cari..." [(ngModel)]="pgn.term" aria-describedby="btn-cari">
                <button class="btn btn-outline-primary" type="button" id="btn-cari" (click)="getData()"><i class="fa fa-search"></i></button>
            </div>
        </div>
        <div class="col-12">
            <div class="table-responsive">
                <table class="table mb-0">
                    <thead style="position: sticky;top: 0">
                        <tr>
                            <th>#</th>
                            <th>Kode</th>
                            <th>Nama</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let n of dataList; let i = index">
                            <td>
                                <button (click)="closeModal(n)" class="btn btn-primary">
                                    <i class="bx bx-copy"></i>
                                </button>
                            </td>
                            <td>{{n.hub_id}}</td>
                            <td>{{n.hub_name}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <ngb-pagination class="d-flex justify-content-center" (pageChange)="loadPage($event)" [(page)]="pgn.currentPage" [collectionSize]="pgn.totalItems" [maxSize]="5" [pageSize]="pgn.itemsPerPage" [rotate]="true"></ngb-pagination>
        </div>
    </div>
</div>
<div class="modal-footer">

    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal('close')">Close</button>
</div>