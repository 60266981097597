import { Injectable } from '@angular/core';
import { io } from 'socket.io-client';
import { environment } from 'src/environments/environment';
 
@Injectable({
  providedIn: 'root'
})
export class SocketioService {

  socket;
  
  constructor(
    
  ) { }

  setupSocketConnection(){
    let currentUser = localStorage.currentUser
    ? JSON.parse(localStorage.currentUser)
    : null;
  
    this.socket=io(`${environment.socketUrl}?token=${currentUser.token}`);

  }
  disconnect() {
    if (this.socket) {
        this.socket.disconnect();
    }
}
}
