import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProvinceService {

  constructor(private httpClient: HttpClient) { }

  public get(param:any){
    let uri = environment.apiUrl+`/area/province?pg=${param.pg}&limit=${param.limit}`;
    if(param.term){
      uri = uri+'&term='+param.term;
    }
    return this.httpClient
      .get(uri)
      .toPromise();
  }

  public getCity(
    pg: any = 1,
    limit: any = 5,
    term: any
  ) {
    let x;
    if (term) {
      x = `limit=${limit}&pg=${pg}&term=${term}`;
    } else {
      x = `limit=${limit}&pg=${pg}`;
    }
    return this.httpClient
      .get(`${environment.apiUrl}/area/hub?${x}`)
      .toPromise();
  }

  public save(form:any){
    let uri = environment.apiUrl+'/area/province';
    return this.httpClient
      .post(uri, form)
      .toPromise();
  }

  public update(id:any, form:any){
    let uri = environment.apiUrl+'/area/province/'+id;
    return this.httpClient
      .post(uri, form)
      .toPromise();
  }

  public delete(id:any){
    let uri = environment.apiUrl+'/area/province/'+id;
    return this.httpClient
      .delete(uri)
      .toPromise();
  }
}
