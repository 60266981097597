<div class="modal-header">
    <h5 class="modal-title mt-0">{{title}}</h5>
    <button type="button" class="btn-close" data-dismiss="modal" (click)="closeModal('dismiss')"><span aria-hidden="true"></span>
    </button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-6">
            <div class="input-group mb-3">
                <input type="text" class="form-control" placeholder="Cari..." (keyup.enter)="getData()"
                    aria-label="Cari..." [(ngModel)]="pgn.term" aria-describedby="btn-cari" />
                <button class="btn btn-outline-primary" type="button" id="btn-cari" (click)="getData()">
                    <i class="fa fa-search"></i>
                </button>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <div class="table-responsive">
                <table class="table align-middle table-nowrap mb-0">
                  <thead>
                    <tr>
                        <th scope="col">No Manifest</th>
                        <th scope="col">Tanggal</th>
                        
                        <th scope="col">Moda</th>
                        <th scope="col">Host</th>
                        <th scope="col">Keterangan</th>
                    </tr>
                </thead>
                  <tbody  *ngFor="let x of dataList; let i = index">
                      <tr>
                           <td> <a href="javascript:void(0)" (click)="pilihData(x)" ngbTooltip="Klik disini untuk memasukan nomor"><i class="bx bx-down-arrow-circle font-size-20 text-primary"></i>{{x.manifest_id}} </a>  </td>
                           <td> {{x.manifest_date  | date : "dd-MM-yyyy" }}</td>
                           
                           <td style="vertical-align: middle;">
                            <img *ngIf="x?.moda_id=='A'" src="assets/images/air-freight.png" alt class="avatar-sm" />
                            <img *ngIf="x?.moda_id=='L'" src="assets/images/land-trucking.png" alt class="avatar-sm" />
                            <img *ngIf="x?.moda_id=='S'" src="assets/images/sea-freight.png" alt class="avatar-sm" />
                            By {{x.moda_id=='A'?'AIR':x.moda_id=='L'?'LAND':'SEA'}}
                          </td>
                          <td><i class="fi flaticon-international-delivery-business-symbol-of-world-grid-with-an-arrow-around align-middle me-1 text-primary font-size-20"></i> {{x.hub_id}} {{x.hub_name}}</td>
                           <td>{{x.description}}</td>
                           <td class="text-center">
                             <div class="btn-group">
                              <button type="button" class="btn btn-sm btn-primary" *ngIf="!x.showDetail" (click)="showDataDetail(i)" ngbTooltip="Lihat Detail"><i class="bx bx-chevrons-down"></i></button>
                              <button type="button" class="btn btn-sm btn-primary" *ngIf="x.showDetail" (click)="showDataDetail(i)" ngbTooltip="Tutup Detail"><i class="bx bx-chevrons-up"></i></button>
                             </div>
                           
                           </td>
                      </tr>
                      <tr *ngIf="x.showDetail">
                        <td colspan="5">
                          <div *ngIf="x.showLoading" class="spinner-border text-primary m-0" role="status">
                            <span class="sr-only">Loading...</span>
                          </div>
                          <table class="table shipment-list-table table-nowrap align-text-top table-borderless p-0">
                            <thead style="position: sticky;top: 0" class="shadow">
                              <tr>
                                <th scope="col" class="lebar">#</th>
                                <th scope="col" class="lebar">No Resi</th>
                                <th scope="col">Pickup</th>
                                <th scope="col">Delivery</th>
                                <th scope="col" class="lebar">Layanan</th>
                              </tr>
                            </thead>
                            <tbody  *ngFor="let n of x?.detail_manifest; let i = index">
                              <tr>
                                <td style="vertical-align: middle;">
                                      <img *ngIf="n?.shipment_detail.moda_id=='A'" src="assets/images/air-freight.png" alt class="avatar-sm" />
                                      <img *ngIf="n?.shipment_detail.moda_id=='L'" src="assets/images/land-trucking.png" alt class="avatar-sm" />
                                      <img *ngIf="n?.shipment_detail.moda_id=='S'" src="assets/images/sea-freight.png" alt class="avatar-sm" />
                                </td>
                                <td style="vertical-align: middle;">
                                  <span *ngIf="n.is_cash" class="badge bg-success float-start" ><i class="bx bx-money"></i></span>
                                  <p class="mb-0">{{n.shipment_detail.shipment_awbm}}</p>
                                   <p class="text-muted">{{n.shipment_detail.shipment_date  | date : "dd-MM-yyyy" }}</p>
                                <td>
                                  <span><i class="bx bx-right-top-arrow-circle font-size-20 text-primary"></i>{{n.shipment_detail.origin}}-{{n.shipment_detail.originname}} </span>
                                  <p class="text-black mb-0 text-wrap">{{n.shipment_detail.partner_name}}</p>
                                  <p class="text-muted mb-0 text-wrap">{{n.shipment_detail.partner_address1}}</p>
                                </td>
                                <td>
                                  <span><i class="bx bx-right-down-arrow-circle font-size-20 text-primary"></i>{{n.shipment_detail.destination}}-{{n.shipment_detail.destinationname}} </span>
                                  <p class="text-black mb-0 text-wrap">{{n.shipment_detail.receiver_name}}</p>
                                  <p class="text-muted mb-0 text-wrap">{{n.shipment_detail.receiver_address1}}</p>
                                </td>
                                <td>
                                  <p class="mb-0 ">{{n.shipment_detail.service_id}}</p>
                                  <p class="text-black mb-0 text-wrap">{{n.shipment_detail.weight|number}} {{n.shipment_detail.uom_id}}</p>
                                  <p *ngIf="n.shipment_detail.moda_id=='A'">Udara</p>
                                  <p *ngIf="n.shipment_detail.moda_id=='L'">Darat</p>
                                  <p *ngIf="n.shipment_detail.moda_id=='S'">Laut</p>
                                  <span class="badge badge-pill badge-soft-info font-size-11" [ngClass]=" { 'badge-soft-danger': n.last_status === 'PICKUP',
                                  'badge-soft-warning':n.last_status === 'MANIFEST' }"> {{n?.last_status_remark}}</span>
                                </td>
                                 
                            </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                  </tbody>
                </table>
              </div>
            </div>  
          </div>
           
        </div>
        <div class="col-lg-12">
          <ngb-pagination  class="d-flex justify-content-center" (pageChange)="loadPage($event)" [(page)]="pgn.currentPage" [collectionSize]="pgn.totalItems" [maxSize]="5" [pageSize]="pgn.itemsPerPage" [rotate]="true"></ngb-pagination>
        </div>
      </div>

</div>
<div class="modal-footer">
      <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal('close')">Close</button>   
  </div>