import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Toast, Toaster } from 'ngx-toast-notifications';
import { ApiService } from 'src/app/services/api.service';
import { GlobalConstants } from 'src/app/services/global';
@Component({
  selector: 'app-modal-hub',
  templateUrl: './modal-hub.component.html',
  styleUrls: ['./modal-hub.component.scss']
})
export class ModalHubComponent implements OnInit {
  dataList:any;
  global={
    ...GlobalConstants.x
  }
  pgn={
    totalItems:0,
    itemsPerPage:0,
    limit:this.global.limitData,
    currentPage:1,
    prevPage:0,
    term:null
  };
  constructor(
    public activeModal:NgbActiveModal,
    private apiService:ApiService,
    private toast:Toaster
  ) { }
  ngOnInit() {
    this.loadPage(1);
  }
  loadPage(page: any) {
    if (page !== this.pgn.prevPage) {
      this.pgn.prevPage = page;
      this.pgn.currentPage=page;
      this.getData();
    }
  }
  async getData(){
    try {
      const res: any  = await this.apiService.getHub(this.pgn.currentPage,this.pgn.limit,this.pgn.term);
      if(res && res.success==true){
        this.dataList = res.data;
        this.pgn.totalItems=res.total;
        this.pgn.itemsPerPage=res.limit;
        // this.cu=res.current;
      }
      } catch (error) {
        this.toast.open({type:'danger',text:error})
      }
  }
  closeModal(sendData) {
    this.activeModal.close(sendData);
  }
}
