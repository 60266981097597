import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
const httpOptions = {
  headers: new HttpHeaders({ Accept: "application/json" }),
};
@Injectable({
  providedIn: "root",
})
export class ApiService {
  constructor(private httpClient: HttpClient) {}
  public qsconvert(params: any) {
    return Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join("&");
  }
  
  public getFilterShipment(
    pg: any = 1,
    limit: any = 20,
    term: any,
    type: string = null,
    cid: string = null
  ) {
    let x;

    if (term) {
      x = type
        ? `limit=${limit}&pg=${pg}&term=${term}&type=${type}`
        : `limit=${limit}&pg=${pg}&term=${term}`;
    } else {
      x = type
        ? `limit=${limit}&pg=${pg}&type=${type}`
        : `limit=${limit}&pg=${pg}`;
    }

    return cid
      ? this.httpClient
          .get(`${environment.apiUrl}/shipment?${x}&contact_id=${cid}`)
          .toPromise()
      : this.httpClient.get(`${environment.apiUrl}/shipment?${x}`).toPromise();
  }

  public getQueryShipment(
    pg: any = 1,
    limit: any = 20,
    term: any,
    type: string = null,
    qryData: any = null
  ) {
    let x;

    if (term) {
      x = type
        ? `limit=${limit}&pg=${pg}&term=${term}&type=${type}`
        : `limit=${limit}&pg=${pg}&term=${term}`;
    } else {
      x = type
        ? `limit=${limit}&pg=${pg}&type=${type}`
        : `limit=${limit}&pg=${pg}`;
    }

    return this.httpClient
      .get(`${environment.apiUrl}/shipment?${x}`)
      .toPromise();
  }

  /**Keberangkatan */

  public getAssignmentManifestOs(pg: any = 1, limit: any = 20, term: any) {
    let x;
    if (term) {
      x = `limit=${limit}&pg=${pg}&term=${term}`;
    } else {
      x = `limit=${limit}&pg=${pg}`;
    }
    return this.httpClient
      .get(`${environment.apiUrl}/assignment/osmanifest?${x}`)
      .toPromise();
  }

  /** */

  public getPickupOs(pg: any = 1, limit: any = 20, term: any) {
    let x;
    if (term) {
      x = `limit=${limit}&pg=${pg}&term=${term}`;
    } else {
      x = `limit=${limit}&pg=${pg}`;
    }
    return this.httpClient
      .get(`${environment.apiUrl}/shipment/pickup/os?${x}`)
      .toPromise();
  }

  public getShipmentByShipmentAwb(id: string) {
    return this.httpClient
      .get(`${environment.apiUrl}/shipment/outbound/${id}?type=shipment_id`)
      .toPromise();
  }

  public getShipmentByShipmentAwbM(id: string, checkType?: String) {
    let url: any;
    if (checkType) {
      url = `${environment.apiUrl}/shipment/outbound/${id}?validate=${checkType}`;
    } else {
      url = `${environment.apiUrl}/shipment/outbound/${id}`;
    }
    return this.httpClient.get(`${url}`).toPromise();
  }

  public getShipmentByShipmentOsManifest(id: string, hub: string) {
    return this.httpClient
      .get(`${environment.apiUrl}/manifest/osbyhub/${hub}/shipment/${id}`)
      .toPromise();
  }

  public updateShipmentIncoming(params: any) {
    console.log(params);

    return this.httpClient
      .put<any>(
        `${environment.apiUrl}/shipment/incomingwh`,
        params,
        httpOptions
      )
      .toPromise();
  }

  public getManifestOsByHub() {
    return this.httpClient
      .get(`${environment.apiUrl}/manifest/osbyhub`)
      .toPromise();
  }

  public getManifestOsByHubDetail(
    hub: string,
    pg: any = 1,
    limit: any = 20,
    term: any
  ) {
    let x;
    if (term) {
      x = `limit=${limit}&pg=${pg}&term=${term}`;
    } else {
      x = `limit=${limit}&pg=${pg}`;
    }
    return this.httpClient
      .get(`${environment.apiUrl}/manifest/osbyhub/${hub}?${x}`)
      .toPromise();
  }
  public getManifest(pg: any = 1, limit: any = 20, term: any) {
    let x;
    if (term) {
      x = `limit=${limit}&pg=${pg}&term=${term}`;
    } else {
      x = `limit=${limit}&pg=${pg}`;
    }
    return this.httpClient
      .get(`${environment.apiUrl}/manifest?${x}`)
      .toPromise();
  }

  public saveManifest(params: any) {
    return this.httpClient
      .post<any>(`${environment.apiUrl}/manifest`, params, httpOptions)
      .toPromise();
  }

  public saveManifestDraft(manifest_id, params: any) {
    return this.httpClient
      .post<any>(`${environment.apiUrl}/manifest/draft/${manifest_id}`, params, httpOptions)
      .toPromise();
  }

  public getContact(
    pg: any = 1,
    limit: any = 20,
    term: any,
    type: string = null
  ) {
    let x;
    if (term) {
      x = `limit=${limit}&pg=${pg}&term=${term}`;
    } else {
      x = `limit=${limit}&pg=${pg}`;
    }
    return this.httpClient
      .get(`${environment.apiUrl}/contact/${type}?${x}`)
      .toPromise();
  }
  public getContactById(type: string, contact_id: string) {
    return this.httpClient
      .get(`${environment.apiUrl}/contact/${type}/${contact_id}`)
      .toPromise();
  }

  public getSubContact(
    pg: any = 1,
    limit: any = 20,
    term: any,
    cid: string = null
  ) {
    let x;
    if (term) {
      x = `limit=${limit}&pg=${pg}&term=${term}`;
    } else {
      x = `limit=${limit}&pg=${pg}`;
    }
    return this.httpClient
      .get(`${environment.apiUrl}/contact/customer/${cid}/subcontact?${x}`)
      .toPromise();
  }

  /**AREA */
  // public getHub(pg:any=1,limit:any=20,term:any,type:string=null):Observable<any>{
  public getHub(pg: any = 1, limit: any = 20, term: any, type: string = null) {
    let x;
    if (term) {
      x = `limit=${limit}&pg=${pg}&term=${term}`;
    } else {
      x = `limit=${limit}&pg=${pg}`;
    }
    return this.httpClient
      .get(`${environment.apiUrl}/area/hub?${x}`)
      .toPromise();
  }
  public getHubByHubId(hub: string) {
    return this.httpClient
      .get(`${environment.apiUrl}/area/hub/${hub}`)
      .toPromise();
  }

  public getDestination(
    pg: any = 1,
    limit: any = 20,
    term: any,
    type: string = null
  ) {
    let x;
    if (term) {
      x = `limit=${limit}&pg=${pg}&term=${term}`;
    } else {
      x = `limit=${limit}&pg=${pg}`;
    }
    return this.httpClient
      .get(`${environment.apiUrl}/area/destination?${x}`)
      .toPromise();
  }

  public uploadFile(formData: any) {
    return this.httpClient
      .post<any>(
        `${environment.apiUrl}/fileupload?type=profile`,
        formData,
        httpOptions
      )
      .toPromise();
  }
  public uploadFileShipment(formData: any) {
    return this.httpClient
      .post<any>(
        `${environment.apiUrl}/fileupload?type=uploads`,
        formData,
        httpOptions
      )
      .toPromise();
  }

  /** END OF AREA */

  /**CONTACT */

  public saveContact(params: any, tipe: string = "customer") {
    return this.httpClient
      .post<any>(`${environment.apiUrl}/contact/${tipe}`, params, httpOptions)
      .toPromise();
  }
  public updateContact(params: any, tipe: string = "customer", cid: string) {
    console.log(params);
    console.log(cid);
    return this.httpClient
      .put<any>(
        `${environment.apiUrl}/contact/${tipe}/${cid}`,
        params,
        httpOptions
      )
      .toPromise();
  }

  public updateHOPic(
   formData:any
  ) {
    return this.httpClient
      .put<any>(
        `${environment.apiUrl}/company/picture?type=profile`,
        formData,
        httpOptions
      )
      .toPromise();
  }

  public updateContactPic(
    cid: string,
    formData: any,
    tipe: string = "customer"
  ) {
    return this.httpClient
      .put<any>(
        `${environment.apiUrl}/contact/${tipe}/${cid}/profile?type=profile`,
        formData,
        httpOptions
      )
      .toPromise();
  }


  public getContactHargaByFiveKeys(
    type: string = "customer",
    contact_id: string,
    origin: string,
    destination: string,
    uom_id: string,
    service_id: string,
    moda_id: string
  ) {
    return this.httpClient
      .get(
        `${environment.apiUrl}/contact/${type}/${contact_id}/price/filter?origin=${origin}&destination=${destination}&uom_id=${uom_id}&service_id=${service_id}&moda_id=${moda_id}`
      )
      .toPromise();
  }

  public saveContactPrice(cid: string, params: any, tipe: string = "customer") {
    return this.httpClient
      .post<any>(
        `${environment.apiUrl}/contact/${tipe}/${cid}/price`,
        params,
        httpOptions
      )
      .toPromise();
  }

  public updateContactPrice(
    cid: string,
    params: any,
    tipe: string = "customer",
    price_id: string
  ) {
    return this.httpClient
      .put<any>(
        `${environment.apiUrl}/contact/${tipe}/${cid}/price/${price_id}`,
        params,
        httpOptions
      )
      .toPromise();
  }

  public deleteContactPrice(
    cid: string,
    tipe: string = "customer",
    price_id: string
  ) {
    return this.httpClient
      .delete<any>(
        `${environment.apiUrl}/contact/${tipe}/${cid}/price/${price_id}`,
        httpOptions
      )
      .toPromise();
  }

 

  public print(report: string, format: string, id: string) {
    console.log(report,format,id);
    return this.httpClient
      .get(
        `${environment.apiUrl}/print/${id}?format=${format}&report=${report}`
      )
      .toPromise();
  }
  /**MASTER */
  public getModa() {
    return this.httpClient.get(`${environment.apiUrl}/master/moda`).toPromise();
  }
  public getUom() {
    return this.httpClient.get(`${environment.apiUrl}/master/uom`).toPromise();
  }
  public getService() {
    return this.httpClient
      .get(`${environment.apiUrl}/master/service`)
      .toPromise();
  }

  public getStatusMain() {
    return this.httpClient
      .get(`${environment.apiUrl}/master/status-list`)
      .toPromise();
  }

  public getGlobalData() {
    return this.httpClient.get(`${environment.apiUrl}/global`).toPromise();
  }

  public getMe() {
    return this.httpClient
      .post<any>(`${environment.apiUrl}/auth/getme`, {}, httpOptions)
      .toPromise();
  }

  public COMPANY = {
    branch: (hub_id: String) => {
      return this.httpClient
        .get(`${environment.apiUrl}/company/branch/${hub_id}`)
        .toPromise();
    },
    branchAllHub: () => {
      return this.httpClient
        .get(`${environment.apiUrl}/company/branch-all`)
        .toPromise();
    },
    branchAll: (pg: any = 1, limit: any = 20, term: any) => {
      let x;
      if (term) {
        x = `limit=${limit}&pg=${pg}&term=${term}`;
      } else {
        x = `limit=${limit}&pg=${pg}`;
      }
      return this.httpClient
        .get(`${environment.apiUrl}/company/branch?${x}`)
        .toPromise();
    },
    branchSave:(params:any)=>{

      return this.httpClient
        .post<any>(
          `${environment.apiUrl}/company/branch`,
          params,
          httpOptions
        )
        .toPromise();

    },
    branchUpdate:(params:any,id:any)=>{
      return this.httpClient
        .put<any>(
          `${environment.apiUrl}/company/branch/${id}`,
          params,
          httpOptions
        )
        .toPromise();
    }
  };
  public ASSIGNMENT_MANIFEST = {

    getAssignmentByAssignmentId:(id:string)=>{
      return this.httpClient
        .get(`${environment.apiUrl}/assignment/manifest/${id}`)
        .toPromise();
    },
    getManifestBerangkatOsByHubId: (hub: String, id: String) => {
      return this.httpClient
        .get(`${environment.apiUrl}/manifest/os/${hub}/${id}`)
        .toPromise();
    },
    getManifestBerangkatOsById: (id: String) => {
      return this.httpClient
        .get(`${environment.apiUrl}/manifest/osbyid/${id}`)
        .toPromise();
    },
    getManifestBerangkatOs: (
      pg: any = 1,
      limit: any = 20,
      term: any,
      hub?: String
    ) => {
      let x;
      if (term) {
        x = `limit=${limit}&pg=${pg}&term=${term}`;
      } else {
        x = `limit=${limit}&pg=${pg}`;
      }

      if (hub) {
        return this.httpClient
          .get(`${environment.apiUrl}/manifest/os/${hub}?${x}`)
          .toPromise();
      } else {
        return this.httpClient
          .get(`${environment.apiUrl}/manifest/os?${x}`)
          .toPromise();
      }
    },
    saveBerangkat: (params: any) => {
      return this.httpClient
        .post<any>(
          `${environment.apiUrl}/assignment/manifest`,
          params,
          httpOptions
        )
        .toPromise();
    },
    updateBerangkat: (id:string, params: any) => {
      return this.httpClient
        .put<any>(
          `${environment.apiUrl}/assignment/manifest/${id}`,
          params,
          httpOptions
        )
        .toPromise();
    },
    getAssignmentManifest: (
      pg: any = 1,
      limit: any = 20,
      term: any,
      start_date?: any,
      end_date?: any
    ) => {
      let x;
      if (term) {
        x = `limit=${limit}&pg=${pg}&term=${term}`;
      } else {
        x = `limit=${limit}&pg=${pg}`;
      }

      return this.httpClient
        .get(`${environment.apiUrl}/assignment/manifest?${x}`)
        .toPromise();
    },
  };
  public ASSIGNMENT_DELIVERY = {
    getAssignmentDeliveryById:(id:number)=>{
      return this.httpClient
        .get(`${environment.apiUrl}/assignment/deliverysheet/${id}`)
        .toPromise();
    },
    getOsDeliveryByAWB:(shipment_awb:any)=>{
      return this.httpClient
      .get(`${environment.apiUrl}/assignment/undeliverysheet/${shipment_awb}`)
      .toPromise();
    },
    getOsDelivery: (pg: any = 1, limit: any = 20, term: any) => {
      let x;
      if (term) {
        x = `limit=${limit}&pg=${pg}&term=${term}`;
      } else {
        x = `limit=${limit}&pg=${pg}`;
      }

      return this.httpClient
        .get(`${environment.apiUrl}/assignment/undeliverysheet?${x}`)
        .toPromise();
    },
    saveDelivery:(params:any)=>{

      return this.httpClient
      .post<any>(`${environment.apiUrl}/assignment/deliverysheet`, params, httpOptions)
      .toPromise();

    },
    updateDelivery:(id:number,params:any)=>{

      return this.httpClient
      .put<any>(`${environment.apiUrl}/assignment/deliverysheet/${id}`, params, httpOptions)
      .toPromise();

    }
  };
  public MANIFEST = {
    manifestById: (manifest_id: String) => {
      return this.httpClient
        .get(`${environment.apiUrl}/manifest/byid/${manifest_id}`)
        .toPromise();
    },
    scanKoli: (params: any) => {
      return this.httpClient
        .post<any>(`${environment.apiUrl}/manifest/scan/koli`, params, httpOptions)
        .toPromise();
    },
    getSkanKoli: (id: String) => {
      return this.httpClient
        .get(`${environment.apiUrl}/manifest/scan/koli?id=${id}`)
        .toPromise();
    },
    scanLoading: (params: any) => {
      return this.httpClient
      .post<any>(`${environment.apiUrl}/manifest/scan/loading`, params, httpOptions)
      .toPromise();
    },
    getScanLoadingByUser: () => {
      return this.httpClient
        .get(`${environment.apiUrl}/manifest/scan/loadingbyuser`)
        .toPromise();
    },
  };
  public INCOMING = {
    os: (pg: any = 1, limit: any = 20, term: any) => {
      let x;
      if (term) {
        x = `limit=${limit}&pg=${pg}&term=${term}`;
      } else {
        x = `limit=${limit}&pg=${pg}`;
      }

      return this.httpClient
        .get(`${environment.apiUrl}/incoming/os?${x}`)
        .toPromise();
    },
    osByAssignmentIdByManifestId: (
      assignmentId: string,
      manifestId: string
    ) => {
      return this.httpClient
        .get(`${environment.apiUrl}/incoming/os/${assignmentId}/${manifestId}`)
        .toPromise();
    },
    confirmIncomingByAssignmentIdManifestId: (
      assignmentId: string,
      manifestId: string,
      tgl: any,
      keterangan: any,
      payload:any,
    ) => {
      return this.httpClient
        .post<any>(
          `${environment.apiUrl}/incoming/confirm/${assignmentId}/${manifestId}`,
          {
            incoming_date: tgl,
            keterangan: keterangan,
            assignmentId: assignmentId,
            manifestId: manifestId,
            payload,
          },
          httpOptions
        )
        .toPromise();
    },
  };
  public SHIPMENT = {
     cariHarga:(id:any)=>{
      return this.httpClient
      .get(`${environment.apiUrl}/shipment/price/${id}`)
      .toPromise();
     },
     deleteShipment:(
      id: number,
    ) =>{
      return this.httpClient
        .delete<any>(
          `${environment.apiUrl}/shipment/delete/${id}`,
          httpOptions
        )
        .toPromise();
    },
    saveShipment: (params: any) => {
      return this.httpClient
        .post<any>(`${environment.apiUrl}/shipment`, params, httpOptions)
        .toPromise();
    },
    updateShipment: (shipment_awb: string, params: any) => {
      return this.httpClient
        .put<any>(
          `${environment.apiUrl}/shipment/edit/${shipment_awb}`,
          params,
          httpOptions
        )
        .toPromise();
    },
    updateShipmentAwbm: (shipment_awb: string, params: any) => {
      return this.httpClient
        .put<any>(
          `${environment.apiUrl}/shipment/update/awbm/${shipment_awb}`,
          params,
          httpOptions
        )
        .toPromise();
    },
    updateStatusManual: (shipment_awb: string, data: any) => {
      return this.httpClient
        .put<any>(
          `${environment.apiUrl}/shipment/statusmanual/${shipment_awb}`,
          data,
          httpOptions
        )
        .toPromise();
    },
  };
 
  public NOTIFICATION = {
    bell: () => {
      return this.httpClient
        .get(`${environment.apiUrl}/notification/bell-notification`)
        .toPromise();
    },
  };
  public;
}
