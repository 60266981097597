import {Component, Input, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Toaster} from "ngx-toast-notifications";
import {ModalPrintComponent} from "src/app/modals/modal-print/modal-print.component";
import {ModalQuestionsComponent} from "src/app/modals/modal-questions/modal-questions.component";
import {AuthenticationService} from "src/app/services/authentication.service";
import {SaInvoiceService} from "src/app/services/sa/sa-invoice.service";
import {Workbook} from "exceljs";
import * as fs from "file-saver";
@Component({
  selector: "app-sa-invoice-list",
  templateUrl: "./sa-invoice-list.component.html",
  styleUrls: ["./sa-invoice-list.component.scss"],
})
export class SaInvoiceListComponent implements OnInit {
  @Input() dataList: any;
  @Input() type: string;
  @Input() tabActive: number;
  loadingSave = false;
  loadingId: any;
  companyId: any;
  constructor(
    private toast: Toaster,
    private router: Router,
    private modal: NgbModal,
    private authService: AuthenticationService,
    private SaInvoice: SaInvoiceService
  ) {
    this.companyId = this.authService.currentUserValue.company;
  }

  ngOnInit(): void {
    console.log(this.tabActive);

    console.log(this.dataList);
  }
  createPayment(invoice_id: string, cid: string, name: string) {
    this.router.navigate(["/sa-finance/paid-invoice"], {
      queryParams: {invoice_id, cid, name},
    });
  }
  showDataDetail(i: any) {
    if (!this.dataList[i]["showDetail"]) {
      this.dataList[i]["showDetail"] = true;
    } else {
      this.dataList[i]["showDetail"] = false;
    }
  }

  voidInvoice(data: any) {
    console.log(this.companyId.company_id);
    const modalRef = this.modal.open(ModalQuestionsComponent, {
      size: "sm",
      keyboard: false,
      backdrop: "static",
    });
    modalRef.componentInstance.title = "Void";
    modalRef.componentInstance.content =
      "Invoice ini akan di batalkan, lanjutkan  ?";
    modalRef.result.then(
      result => {
        console.log(data);
        if (result) {
          this.nowVoid(data);
        } else {
        }
      },
      reason => {
        console.log("res", reason);
      }
    );
  }

  async nowVoid(data: any) {
    this.loadingSave = true;
    this.loadingId = data.id;
    const res: any = await this.SaInvoice.voidInvoice(
      this.companyId.company_id,
      data.id
    );
    console.log(res);
    if (res.success) {
      this.loadingSave = false;
      this.toast.open({type: "success", text: "Invoice berhasil di void"});
    } else {
      this.loadingSave = false;
      return this.toast.open({type: "danger", text: res.err});
    }
  }
  openPrintModal(typePrint: string, id: string) {
    const modalRef = this.modal.open(ModalPrintComponent, {
      // scrollable: true,
      size: "xl",
    });
    modalRef.componentInstance.fromParent = {
      format: typePrint,
      id,
      report: "INVOICE",
    };
    modalRef.result.then(
      result => {},
      reason => {
        console.log(reason);
      }
    );
  }

  gotoPage(invoice: any) {}

  async downloadExcel(data: any) {
    const res: any = await this.SaInvoice.getInvoice(data.id,this.companyId.company_id);
    const item = {...res.data};
    console.log('====================================');
    console.log(item);
    console.log('====================================');
    const invObj = {
        No: "",
        Date: "",
        Shipment: "",
        Origin: "",
        Dest: "",
        Koli: "",
        UOM: "",
        Harga: "",
        ReceivedBy: "",
        BiayaKirim: "",
        LainLain: "",
        Total: "",
      };
    let invList = [];
    let i = 0;
    for (const d of item.invoice_detail) {
      invList.push({
        No: i+1,
        Date: new Date(d.shipment_detail.shipment_date).toLocaleString().substring(0,10),
        Shipment: d.shipment_detail.shipment_awbm,
        Origin: d.shipment_detail.origin,
        Dest: d.shipment_detail.destination,
        Koli: d.shipment_detail.qty,
        UOM: `${d.shipment_detail.weight} ${d.shipment_detail.uom_id}`,
        Harga: d.shipment_detail.charge / d.shipment_detail.weight_invoice,
        ReceivedBy: `${d.shipment_detail.received_by} ${new Date(d.shipment_detail.received_date).toLocaleString()}`,
        BiayaKirim: d.shipment_detail.charge,
        LainLain: d.shipment_detail.pickup_charge+d.shipment_detail.packing_charge+d.shipment_detail.others_charge+d.shipment_detail.insurance_value,
        Total: d.shipment_detail.sub_total,
      });
      i++;
    }

    

    const title = "INVOICE";
    const header = Object.keys(invObj);
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("INVOICE");
    worksheet.addRow([]);
    worksheet.addRow([]);
    // Add new row
    const titleRow = worksheet.addRow([title]);
    // Set font family, font size, and style in title row.
    titleRow.font = {name: "Saysettha OT", family: 4, size: 10, bold: true, color: {argb: 'FFFFFF'}};

    worksheet.mergeCells(3,1,3,12);

    worksheet.getCell('A3').fill = {
      type: 'pattern',
      pattern:'solid',
      bgColor:{argb:'4B56D2'},
      fgColor:{argb:'4B56D2'},
    };

    worksheet.getCell('A3').alignment = { vertical: 'middle', horizontal: 'center' };

    worksheet.addRow([]);
    worksheet.addRow([]);
    worksheet.addRow([]);
    worksheet.addRow([]);
    worksheet.addRow([]);
    worksheet.addRow([]);
    worksheet.addRow([]);
    // Add Header Row
    const headerRow = worksheet.addRow(header);
    // Cell Style : Fill and Border
    headerRow.eachCell(cell => {
      cell.font = {name: "Saysettha OT", bold: false, size: 8 ,color: {argb: 'FFFFFF'}};
      cell.fill = {
        type: 'pattern',
        pattern:'solid',
        bgColor:{argb:'4B56D2'},
        fgColor:{argb:'4B56D2'},
      };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
    });

    worksheet.getCell('A4').value = 'Kepada';
    worksheet.getCell('A6').value = item.contact_name;
    worksheet.getCell('A7').value = item.contact_bill_address.substring(0,40);
    worksheet.getCell('A8').value = item.contact_bill_address.substring(41,100);

    worksheet.getCell('I4').value = `No #:${item.invoice_no}`;
    worksheet.getCell('I6').value = `Tanggal`;
    worksheet.getCell('I7').value = `Hal`;
    worksheet.getCell('I8').value = `Payment`;

    worksheet.getCell('J6').value = `: ${new Date(item.invoice_date).toLocaleString().substring(0,10)}`;
    worksheet.getCell('J7').value = `: Tagihan Jasa Pengiriman`;
    worksheet.getCell('J8').value = `: Cash / Transfer`;



    // Add Data and Conditional Formatting
    invList.forEach(d => {
      let row = worksheet.addRow(Object.values(d));
      row.font = {name: "Saysettha OT",size: 8, bold: false};
    });

    worksheet.addRow([`${item.amount_text} Rupiah #`,'','','','','','','','','','Sub Total',item.subtotal])
    .font = {name: "Saysettha OT",size: 8, bold: false}

    worksheet.addRow(['','','','','','','','','','',item.tax_percent ? `Ppn (${item.tax_percent}%)` : '', item.tax_percent ? item.tax_amount.toFixed(0): ''])
    .font = {name: "Saysettha OT",size: 8, bold: false}

    worksheet.addRow(['','','','','','','','','','','Total',item.amount])
    .font = {name: "Saysettha OT",size: 8, bold: true}

    workbook.xlsx.writeBuffer().then(excelData => {
      const blob = new Blob([excelData], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(blob, `invoice_${item.id}.xlsx`);
    });
  }
}
