<div class="table-responsive">
    <table class="table mb-0">
        <thead class="thead-dark">

            <tr>
                <th style="width: 70px;">#</th>
                <th>No Invoice</th>
                <th *ngIf="tabActive===5">Type</th>
                <th>Tanggal Print</th>
                <th>Tanggal Terima</th>
                <th>Tanggal JT</th>
                <th>Nama</th>

                <th>Aging (Hari) </th>
                <th>Total</th>

                <th>Paid</th>
                <th>Balance</th>

                <th style="width:100px;">Actions</th>
            </tr>
        </thead>
        <tbody *ngFor="let n of dataList;let i = index">
            <tr [ngClass]="{ 'bg-danger': n.is_void, 'bg-success': n.balance === 0, 'text-white':n.is_void || n.balance === 0}">
                <td class="text-center">
                    <div class="btn-group" role="group" aria-label="Basic example">
                        <button type="button" class="btn btn-sm btn-outline-primary" *ngIf="!n.showDetail" (click)="showDataDetail(i)" ngbTooltip="Lihat Detail">{{i+1}} <i
                                class="bx bx-chevrons-down"></i></button>
                        <button type="button" class="btn btn-sm btn-outline-primary" *ngIf="n.showDetail" (click)="showDataDetail(i)" ngbTooltip="Tutup Detail">{{i+1}} <i
                                class="bx bx-chevrons-up"></i></button>
                    </div>
                </td>
                <td>{{n.invoice_no}}</td>
                <td *ngIf="tabActive===5">{{n.invoice_type}}</td>
                <td>{{n.invoice_date| date : "dd-MM-yyyy" }}</td>
                <td>{{n.received_date| date : "dd-MM-yyyy" }}</td>
                <td>{{n.invoice_due_date| date : "dd-MM-yyyy" }}</td>
                <td>{{n.contact_name}}({{n.contact_id}})</td>

                <td></td>
                <td class="text-end">{{n.amount|number}}</td>
                <td class="text-end">{{n.paid|number}}</td>
                <td class="text-end">{{n.balance|number}}</td>
                <td class="text-center" *ngIf="!n.is_void">
                    <div class="btn-group" role="group" aria-label="Basic example">
                        <a href="/sa-invoice/edit/{{n.id}}" role="button" class="btn btn-sm btn-outline-secondary" *ngIf="!n.is_paid && !n.invoice_cash" ngbTooltip="Edit Invoice"><i
                                class="bx bx-edit-alt"></i></a>
                        <button role="button" class="btn btn-sm btn-danger" *ngIf="!n.is_paid && !n.invoice_cash" ngbTooltip="void Invoice" (click)="voidInvoice(n)"> <i  *ngIf="loadingSave && loadingId==n.id" class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>Void</button>
                        <button type="button" *ngIf="n.balance !== 0" class="btn btn-sm btn-outline-success" ngbTooltip="Pembayaran " (click)="createPayment(n.invoice_id,n.contact_id,n.contact_name)"><i class="bx bx-money"></i></button>
                        <button type="button" class="btn btn-sm btn-outline-info" ngbTooltip="Cetak Invoice " (click)="openPrintModal('A',n.id)"><i class="bx bx-printer"></i></button>
                        <button type="button" class="btn btn-sm btn-outline-warning" ngbTooltip="Download Invoice " (click)="downloadExcel(n)"><i class="bx bx-file"></i></button>

                    </div>
                </td>
                <td *ngIf="n.is_void">
                    Void
                </td>
            </tr>
            <tr *ngIf="n.showDetail">
                <td colspan="12">

                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card">
                                <div class="card-body">

                                    <div class="row">
                                        <div class="col-6">
                                            <address>
                                                <strong>Kepada :</strong><br>
                                                {{n.contact_name}}<br>
                                                {{n.contact_bill_address}}
                                            </address>
                                        </div>
                                        <div class="col-6 text-sm-end">
                                            <address>
                                                <strong>Informasi Internal </strong><br>
                                                Cetak Oleh : {{n.print_by}}<br>
                                              
                                            </address>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6 mt-3">
                                            <address>
                                                <strong>Pembayaran :</strong><br>
                                               <br>
                                               
                                            </address>
                                        </div>
                                        <div class="col-6 mt-3 text-sm-end">
                                            <address>
                                                <strong>Tanggal Invoice:</strong><br>
                                                {{n.invoice_date| date : "dd-MM-yyyy" }}<br><br>
                                            </address>
                                        </div>
                                    </div>
                                    <div class="py-2 mt-3">
                                        <h3 class="font-size-15 fw-bold">Detail</h3>
                                    </div>
                                    <div class="table-responsive">
                                        <table class="table">
                                            <thead class="thead-dark">
                                                <tr>
                                                    <th>#</th>
                                                    <th sortable="shipment_awbm">No Resi</th>
                                                    <th sortable="shipment_date">Tanggal</th>
                                                    <th sortable="shipment_do">Reff</th>
                                                    <th sortable="origin">Asal</th>
                                                    <th sortable="destination">Tujuan</th>

                                                    <th sortable="receiver_name">Penerima</th>
                                                    <th sortable="moda_id">Moda</th>
                                                    <th sortable="service_id">Layanan</th>
                                                    <th sortable="weight">Berat</th>
                                                    <th sortable="qty">koli</th>
                                                    <th sortable="qty">Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let x of n.invoice_detail;let y = index">
                                                    <td>{{y+1}}

                                                    </td>
                                                    <td>
                                                        {{x.shipment_detail.shipment_awbm}}
                                                    </td>
                                                    <td>
                                                        <p class="text-muted">{{x.shipment_detail.shipment_date | date : "dd-MM-yyyy" }}</p>
                                                    </td>
                                                    <td>
                                                        <p class="text-muted">{{x.shipment_detail.shipment_do }}</p>
                                                    </td>
                                                    <td>{{x.shipment_detail.origin}}-{{x.shipment_detail.originname}}</td>
                                                    <td>{{x.shipment_detail.destination}}-{{x.shipment_detail.destinationname}}</td>

                                                    <td>{{x.shipment_detail.receiver_name}}</td>
                                                    <td>{{x.shipment_detail.moda_id}}</td>
                                                    <td>{{x.shipment_detail.service_id}}</td>
                                                    <td>{{x.shipment_detail.weight}} {{x.shipment_detail.uom_id}}</td>
                                                    <td>{{x.shipment_detail.qty}}</td>
                                                    <td class="text-right">{{x.shipment_detail.total|number}}</td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                    <b>Tanggal Payment :</b>
                                    <ul>
                                        <li *ngFor="let item of n.payment">
                                            {{item.invoice_payment.payment_date | date : "dd-MM-yyyy"}} : {{item.invoice_payment.description}}
                                        </li>
                                    </ul>
                                    <!-- <div class="d-print-none">
                                        <div class="float-end">
                                            <a href="javascript:window.print()" class="btn btn-success"><i
                                                    class="fa fa-print"></i></a>
                                            <a href="javascript: void(0);" class="btn btn-primary w-md ms-1">Send</a>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>