import { MenuItem } from "../models/menu.models";
const MENU:MenuItem[]=[
    {
        id: 1000,
        label: 'Cabang ',
        isTitle: true
    },
    {
        id: 1010,
        label: 'Dashboard',
        icon: 'bx bx-home-circle',
        subItems: [
            {
                id: 1011,
                label: 'Dashboard Utama',
                link: '/',
                parentId: 1010
            },
            // {
            //     id: 1012,
            //     label: 'Customer Service',
            //     link: '/',
            //     parentId: 1010
            // },
            // {
            //     id: 1013,
            //     label: 'Finance',
            //     link: '/',
            //     parentId: 1010
            // }
        ]
    },
    {
        id: 1020,
        label: 'Shipment',
        icon: 'fi flaticon-logistics-package',
        subItems: [
            {
                id: 1021,
                label: 'Daftar Pengiriman',
                link: '/shipment/pickup',
                parentId: 1020
            },
            {
                id: 1022,
                label: 'Input Pengiriman',
                link: '/shipment/entry',
                parentId: 1020
            },
            {
                id: 1023,
                label: 'Konfirmasi Incoming di WH',
                link: '/shipment/osincomingwh',
                parentId: 1020
            },
        ]
    },  
    {
        id: 1030,
        label: 'Manifest',
        icon: 'fi flaticon-delivery-transportation-machine',
        subItems: [
            {
                id: 1031,
                label: 'Daftar unManifest',
                link: '/manifest/unmanifest',
                parentId: 1030
            },
            {
                id: 1033,
                label: 'Daftar Manifest',
                link: '/manifest/view',
                parentId: 1030
            },
            {
                id: 1034,
                label: 'Input Manifest',
                link: '/manifest/entry',
                parentId: 1030
            },
            {
                id: 1035,
                label: 'Input Manifest & Koli',
                link: '/manifest/entry/koli',
                parentId: 1031
            },
        ]
    },
    {
        id: 1040,
        label: 'Assignment Manifest',
        icon: 'fi flaticon-airplane-around-earth',
        subItems: [
            {
                id: 1041,
                label: 'OS Keberangkatan',
                link: '/assignment/manifest-os',
                parentId: 1040
            },
            {
                id: 1042,
                label: 'Daftar Keberangkatan ',
                link: '/assignment/manifest-view',
                parentId:1040
            },
            {
                id: 1043,
                label: 'Input Keberangkatan',
                link: '/assignment/manifest-entry',
                parentId:1040
            },
        ]
    },
    {
        id: 1050,
        label: 'Kedatangan',
        icon: 'fi flaticon-airplane-in-vertical-ascending-position',
        subItems: [
            {
                id: 1051,
                label: 'Informasi Kedatangan ',
                link: '/arrival/arrival-view',
                parentId: 1050
            }
        ]
    },
    {
        id: 1060,
        label: 'Assignment Delivery',
        icon: 'fi flaticon-man-standing-with-delivery-box',
        link: '/delivery-sheet',
    },
    {
        id: 1070,
        label: 'Customer Services',
        icon: 'fi flaticon-talking-by-phone-auricular-symbol-with-speech-bubble',
        subItems: [
            {
                id: 1071,
                label: 'Status Resi',
                link: '/status/status-resi',
                parentId: 1070
            },
            {
                id: 1072,
                label: 'Revisi Status',
                link: '/status/status-resi-update',
                parentId:1070
            },
            // {
            //     id: 1073,
            //     label: 'Delete Shipment',
            //     link: '/status/status-delete',
            //     parentId:1070
            // }
        ]
    },
    {
        id: 1080,
        label: 'SMU',
        icon: 'fi flaticon-package-cube-box-for-delivery',
        subItems: [
            {
                id: 1081,
                label: 'Sewa Gudang Outbound',
                
                link: '/smu/view-outbound',
                parentId: 1080
            },
            {
                id: 1082,
                label: 'Sewa Gudang Inbound',
                link: '/smu/view-inbound',
                parentId:1080
            }
        ]
    },
    {
        id: 1500,
        label: 'Contact',
        icon: 'bx bx-user-pin',
        subItems: [
            {
                id: 1501,
                label: 'Customer',
                link: '/contact/customer',
                parentId: 1500
            },
            {
                id: 1502,
                label: 'Vendor',
                link: '/contact/vendor',
                parentId: 1500
            },
            {
                id: 1503,
                label: 'Driver',
                link: '/contact/driver',
                parentId: 1500
            },
            // {
            //     id: 1504,
            //     label: 'Karyawan',
            //     link: '/contact/staff',
            //     parentId: 1500
            // },
            // {
            //     id: 1505,
            //     label: 'SMU',
            //     link: '/contact/other',
            //     parentId: 1500
            // },
        ]
    },
]
const MENUADMIN:MenuItem[]=[
    {
        id: 2000,
        label: 'Super Admin',
        isTitle: true
    },
    {
        id: 2010,
        label: 'Perusahaan',
        icon: 'bx bxs-building',
        subItems: [
            {
                id: 2011,
                label: 'Head Office',
                link: '/company/ho',
                parentId: 2010
            },
            {
                id: 2012,
                label: 'Cabang Agen',
                link: '/company/branch',
                parentId:2010
            }
        ]
    },
    {
        id: 2020,
        label: 'Shipment',
        icon: 'fas fa-money-bill-wave',
        subItems: [
            {
                id: 2021,
                label: 'Semua',
                link: '/sa-shipment/main',
                parentId: 2020
            },
            {
                id: 2022,
                label: 'Uninvoice',
                link: '/sa-shipment/uninvoice',
                parentId: 2020
            },
            {
                id: 2023,
                label: 'Zero Amount',
                link: '/sa-shipment/zeroamount',
                parentId: 2020
            },
           
            {
                id: 2024,
                label: 'Cash',
                link: '/sa-shipment/cash',
                parentId: 2020
            },
            {
                id: 2025,
                label: 'Archive',
                link: '/sa-shipment/paid',
                parentId: 2020
            },
            
            {
                id: 2027,
                label: 'Delete Shipment',
                link: '/status/status-delete',
                parentId:2020
            }
        ]
    },
    {
        id: 2030,
        label: 'Invoice',
        icon: 'bx bx-food-menu',
        subItems: [
            {
                id: 2031,
                label: 'Daftar Invoice',
                link: '/sa-invoice/list',
                parentId: 2030
            },
            {
                id: 2032,
                label: 'Buat Invoice',
                link: '/sa-invoice/create',
                parentId: 2030
            },
            {
                id: 2035,
                label: 'Invoice Sewa Gudang - Outbound',
                link: '/sa-invoice/sewagudang/create',
                parentId: 2030
            },

            {
                id: 2036,
                label: 'Invoice Sewa Gudang - Inbound',
                link: '/sa-invoice/sewagudanginbound/create',
                parentId: 2030
            },
            {
                id: 2033,
                label: 'Validasi Invoice',
                link: '/sa-shipment/validation',
                parentId: 2030
            },
            {
                id: 2033,
                label: 'Validasi Cash',
                link: '/sa-shipment/validation-cash',
                parentId: 2030
            },
           
        ]
    },
    {
        id: 2040,
        label: 'Finance',
        icon: 'bx bx-food-menu',
        subItems: [
            {
                id: 2041,
                label: 'Daftar Pembayaran',
                link: '/sa-finance/paid-invoice-view',
                parentId: 2040
            },
            {
                id: 2042,
                label: 'Pembayaran Invoice',
                link: '/sa-finance/paid-invoice',
                parentId: 2040
            },
        ]
    },
    {
        id: 1600,
        label: 'Admin Settings',
        icon: 'bx bx-cog',
        subItems: [
            {
                id: 1601,
                label: 'Daftar user',
                link: '/sa-admin/user-list',
                parentId: 1600
            },
            {
                id: 1602,
                label: 'Area',
                link: '/mastersetting/area',
                parentId: 1600
            },
            {
                id: 1603,
                label: 'UOM',
                link: '/mastersetting/uom',
                parentId:1600
            },
            {
                id: 1604,
                label: 'Layanan',
                link: '/mastersetting/service',
                parentId:1600
            },
            {
                id: 1605,
                label: 'Bank',
                link: '/mastersetting/bank',
                parentId:1600
            }
        ]
    },
]
export {MENU,MENUADMIN}