<div class="modal-header">
    <h5 class="modal-title mt-0">{{title}}</h5>
    <button type="button" class="btn-close" data-dismiss="modal" (click)="closeModal(false)"><span aria-hidden="true"></span>
    </button>
</div>
<div class="modal-body">
<p>{{content}}</p>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-success" (click)="closeModal(true)">Ya</button>
    <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="closeModal(false)">Tidak</button>
  </div>