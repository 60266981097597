import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Toaster } from "ngx-toast-notifications";
import { concat, of, Subject } from "rxjs";
import { distinctUntilChanged, switchMap, tap } from "rxjs/operators";
import { ModalHubComponent } from "src/app/modals/modal-hub/modal-hub.component";
import { ApiService } from "src/app/services/api.service";
import { AuthenticationService } from "src/app/services/authentication.service";
import { GlobalConstants } from "src/app/services/global";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: 'app-set-user',
  templateUrl: './set-user.component.html',
  styleUrls: ['./set-user.component.scss']
})
export class SetUserComponent implements OnInit {
  @Input() contactData: any;
  @Input() role:string;
  @Output() klikYes: EventEmitter<any> = new EventEmitter();
  dataUser:any;
  validateForm!: FormGroup;
  submit: boolean = false;
  userValue:any;
  global = {...GlobalConstants.x};
  constructor(
    private apiService: UserService,
    private toast: Toaster,
    private fb: FormBuilder,
    private authService: AuthenticationService,
    private modal:NgbModal
  ) {
    this.validateForm = this.fb.group({
      contact_id: [{ value: null, disabled: true }, [Validators.required]],
      name: [null, [Validators.required]],
      email: [null, [Validators.required,Validators.email]],
      password: ['', [Validators.required]],
    });
   }

  ngOnInit(): void {
    console.log(this.role);
    console.log(this.contactData);
    this.userValue = this.authService.currentUserValue;
    this.fillFormData(this.contactData);


  }

  fillFormData(data: any) {
    this.validateForm.controls["contact_id"].setValue(data.contact_id);
    this.validateForm.controls["name"].setValue(data.name);
    this.validateForm.controls["email"].setValue(data.email);
  }
  
  get form() {
    return this.validateForm.controls;
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  batalForm(){
    this.klikYes.emit({s:false})

  }
  async submitForm() {
    console.log('-->>>>>>')
    this.submit = true;
    let prm ={
      contact_id:this.validateForm.controls['contact_id'].value,
      company_id:this.userValue.company_id,
      name:this.validateForm.controls['name'].value,
      email:this.validateForm.controls['email'].value,
      password:this.validateForm.controls['password'].value
    }
    if (this.validateForm.invalid) {
      return this.toast.open({ type: "danger", text: "Mohon isi field yang di perlukan" });
    }

    const res:any = await this.apiService.driver(prm);
    if (res.success){
      
      this.toast.open({ type: "success", text: "User berhasil di update" });
      this.klikYes.emit({s:true})
      
    }else{
      return this.toast.open({ type: "danger", text: res.error });
    }



  }
}
