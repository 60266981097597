<div class="modal-header">
    <h5 class="modal-title mt-0">PRINT VIEWER</h5>
    <div *ngIf="loading" class="spinner-border text-primary m-1" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <button type="button" class="btn-close" data-dismiss="modal" (click)="closeModal('dismiss')"><span aria-hidden="true"></span>
    </button>
</div>
<div class="modal-body">
    <object [data]="resultPrint + '#toolbar=1&navpanes=0' | safeUrl" type="application/pdf" width="100%" height="600px"></object>

</div>
<div class="modal-footer">

    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal('close')">Tutup</button>
</div>