import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { map, tap, switchMap } from 'rxjs/operators';
import { BehaviorSubject, from, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
  constructor(
    private http: HttpClient,
    private router: Router,
  ) {
    this.currentUserSubject = new BehaviorSubject<any>(localStorage.getItem('currentUser'));
    this.currentUser = this.currentUserSubject.asObservable();

   }

   public get currentUserValue() {
     
    if (typeof this.currentUserSubject.value === 'object'){

      return this.currentUserSubject.value;
    }else{
      return JSON.parse(this.currentUserSubject.value);
    }
  }
  public setCurrentUserValue(data) {
    

    
    this.currentUserSubject.next(data);
  }
  public get currentUserData() {
    let dataX;
    if (typeof this.currentUserSubject.value === 'object'){
      dataX=this.currentUserSubject.value;
    }else{
      dataX = this.currentUserSubject.value?JSON.parse(this.currentUserSubject.value).data:null;
    }
    return {...dataX};
  }
  public get publicTitle(){
    let dataX = this.currentUserSubject.value?JSON.parse(this.currentUserSubject.value).data.agen:null;
    return {...dataX}
  }

  login(email: string, password: string) {
    return this.http
      .post<any>(`${environment.apiUrl}/auth`, { email, password }).toPromise();
  }
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
    this.router.navigate(['/account/login']);
    return false;
  }

}
