<div class="modal-header">
    <h5 class="modal-title mt-0">Daftar OS Pembayaran {{companyId}}</h5>
    <button type="button" class="btn-close" data-dismiss="modal" (click)="closeModal('dismiss')"><span aria-hidden="true"></span>
    </button>
</div>
<div class="modal-body">
        <div class="row">
          <div class="col-6">
          </div>
          <div class="col-6">
            <div class="input-group mb-3">
              <input type="text" class="form-control" placeholder="Cari..." (keyup.enter)="getData()" aria-label="Cari..." [(ngModel)]="pgn.term"  aria-describedby="btn-cari">
              <button class="btn btn-outline-primary" type="button" id="btn-cari" (click)="getData()"><i class="fa fa-search"></i></button>
            </div>
          </div>
          <div class="col-12">
            <div class="table-responsive">
                <table class="table mb-0">
                    <thead class="thead-dark">
                
                        <tr>
                            <th style="width: 70px;">#</th>
                            <th>No Invoice</th>
                            <th>Tanggal Print</th>
                            <th>Tanggal Terima</th>
                            <th>Tanggal JT</th>
                            <th>Nama</th>
                
                            <th>Aging (Hari)</th>
                            <th>Total</th>
                
                            <th>Paid</th>
                            <th>Balance</th>
                 
                        </tr>
                    </thead>
                    <tbody *ngFor="let n of dataList;let i = index">
                        <tr>
                            <td class="text-center">
                                {{(pgn.limit * (pgn.currentPage-1))+i+1}}
                            </td>
                            <td><a href="javascript:void(0)" (click)="pilihData(n)" ngbTooltip="Pilih {{n.invoice_no}}">{{n.invoice_no}}</a></td>
                            <td>{{n.invoice_date| date : "dd-MM-yyyy" }}</td>
                            <td>{{n.received_date| date : "dd-MM-yyyy" }}</td>
                            <td>{{n.invoice_due_date| date : "dd-MM-yyyy" }}</td>
                            <td>{{n.contact_name}}({{n.contact_id}})</td>
                
                            <td></td>
                            <td class="text-end">{{n.amount|number}}</td>
                            <td class="text-end">{{n.paid|number}}</td>
                            <td class="text-end">{{n.balance|number}}</td>
                            
                        </tr>
                      
                    </tbody>
                </table>
            </div>
            <ngb-pagination  class="d-flex justify-content-center" (pageChange)="loadPage($event)" [(page)]="pgn.currentPage"
            [collectionSize]="pgn.totalItems" [maxSize]="5" [pageSize]="pgn.itemsPerPage"
            [rotate]="true"></ngb-pagination>
          </div>
        </div>
</div>
<div class="modal-footer">
  
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal('close')">Close</button>   
</div>