import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Toaster } from 'ngx-toast-notifications';
import { ApiService } from 'src/app/services/api.service';
import { GlobalConstants } from 'src/app/services/global';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-modal-shipment',
  templateUrl: './modal-shipment.component.html',
  styleUrls: ['./modal-shipment.component.scss']
})
export class ModalShipmentComponent implements OnInit {
  @Input() fromParent;
  showInfo:boolean=true;
  showDetail:boolean=false;
  dataDetail:any;
  judul:string="Daftar Shipment";
  type:string;
  cid:string;
  hub:string;
  isTrx:boolean=false;
  dataList:any;
  isSelect:boolean=false;
  isLoading:boolean=false;
  global={
    ...GlobalConstants.x
  }
  pgn={
    totalItems:0,
    itemsPerPage:0,
    limit:this.global.limitData,
    currentPage:1,
    prevPage:0,
    term:null
  };
  constructor(
    public activeModal:NgbActiveModal,
    private apiService:ApiService,
    private toast:Toaster
  ) { 
    this.pgn={
      totalItems:0,
      itemsPerPage:0,
      limit:this.global.limitData,
      currentPage:1,
      prevPage:0,
      term:null
    };
  }
  ngOnInit() {
    this.type=this.fromParent.type;
    this.cid=this.fromParent.cid;
    this.hub=this.fromParent.hub;
    this.isTrx=this.fromParent.isTrx?true:false;
    this.isSelect=this.fromParent.isSelect||false;
    this.loadPage(1);
  }
  loadPage(page: any) {
    if (page !== this.pgn.prevPage) {
      this.pgn.prevPage = page;
      this.pgn.currentPage=page;
      this.getData(this.type,this.cid,this.hub);
    }
  }
  async getData(tipe:string,cid:string,hub:string){
    try {
    if (tipe=='TOTALSHIPMENT'){
      this.judul="Daftar Seluruh Shipment"
    }
    if (tipe=='OSINCOMINGWH'){
      this.judul="Daftar Outstanding Di Warehouse"
    }
    if (tipe=='OSDELVIERY'){
      this.judul="Daftar Outstanding Delivery"
      

    }
    this.isLoading=true;

    console.log('-->>',tipe);
      if (tipe=='OSMANIFEST' && hub){
        const res: any  = await this.apiService.getManifestOsByHubDetail(hub,this.pgn.currentPage,this.pgn.limit,this.pgn.term);
        if(res && res.success==true){
        this.dataList = res.data;
        this.pgn.totalItems=res.total;
        this.pgn.itemsPerPage=res.limit;
        }
      } else if (tipe=='OSDELIVERY'){
        
        const res: any  = await this.apiService.ASSIGNMENT_DELIVERY.getOsDelivery(this.pgn.currentPage,this.pgn.limit,this.pgn.term);
        console.log(res)
        if(res && res.success==true){
        this.dataList = res.data;
        this.pgn.totalItems=res.total;
        this.pgn.itemsPerPage=res.limit;
        }
      } else {
        const res: any  = await this.apiService.getFilterShipment(this.pgn.currentPage,this.pgn.limit,this.pgn.term,tipe,cid);
        if(res && res.success==true){
          this.dataList = res.data;
          this.pgn.totalItems=res.total;
          this.pgn.itemsPerPage=res.limit;
        }
      }
      this.isLoading=false;
      } catch (error) {
        this.toast.open({type:'danger',text:error})
      }
  }
  pilihData(data:any){
    this.activeModal.close(data.shipment_awbm);
  }
  showData(data:any){
    this.showInfo=false;
    this.showDetail=true;
    this.dataDetail=data;
  }
  hideData(){
    this.showInfo=true;
    this.showDetail=false;
    this.dataDetail={};
  }
  closeModal(sendData) {
    this.activeModal.close(sendData);
  }
  getDataShipment(type:string){
  }
}
