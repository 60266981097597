<div class="modal-header">
    <h5 class="modal-title mt-0">Daftar Cabang / Agen {{fromParent.hub}}</h5>
    <button type="button" class="btn-close" data-dismiss="modal" (click)="closeModal('dismiss')"><span aria-hidden="true"></span>
    </button>
</div>
<div class="modal-body">
        <div class="row">
          <div class="col-12">
            <div class="table-responsive">
              <table class="table mb-0">
                <thead style="position: sticky;top: 0">
                  <tr>
                    <th>Nama</th>
                    <th>Alamat</th>
                  </tr>
                </thead>
                <tbody>
                  <tr  *ngFor="let n of dataList; let i = index">
                    <td class="text-start"><a href="javascript:void(0)" (click)="closeModal(n)" ngbTooltip="Pilih {{n.company_name}}">   {{n.company_id}} {{n.company_name}}</a></td>
                    <td class="text-start">{{n.address1}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal('close')">Close</button>   
</div>