import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Toaster } from 'ngx-toast-notifications';
@Component({
  selector: 'app-modal-validasi',
  templateUrl: './modal-validasi.component.html',
  styleUrls: ['./modal-validasi.component.scss']
})
export class ModalValidasiComponent implements OnInit {
  @Input() fromParent;
  constructor(
    public activeModal:NgbActiveModal,
    private toast:Toaster
  ) { }

  
  ngOnInit(): void {
  }

  closeModal(sendData) {
    console.log(sendData);
    this.activeModal.close({
      index:this.fromParent.index,
      data:sendData
    });
  }

}
