<form class="form-data" [formGroup]="validateForm" (ngSubmit)="submitForm()">

  <div class="mb-3 row">
    <label for="contact_reff" class="col-md-3 col-form-label">Contact Id</label>
    <div class="col-md-4">
      <input
        type="text"
        class="form-control"
        id="contact_id"
        formControlName="contact_id"
        placeholder="Contact Id"
        readonly="true"
        [ngClass]="{ 'is-invalid': submit && form.contact_id.errors }"
      />
      <div *ngIf="submit && form.contact_id.errors" class="invalid-feedback">
        <span *ngIf="form.contact_id.errors.required"
          >Mohon isi contact id (ex : abc )</span
        >
      </div>
    </div>
  </div>
  <div class="mb-3 row">
    <label for="name" class="col-md-3 col-form-label">Nama Driver/Kurir</label>
    <div class="col-md-9">
      <input
        class="form-control"
        type="text"
        placeholder="Nama Perusahaan/Perorangan"
        id="name"
        formControlName="name"
        [ngClass]="{ 'is-invalid': submit && form.name.errors }"
      />
      <div *ngIf="submit && form.name.errors" class="invalid-feedback">
        <span *ngIf="form.name.errors.required"
          >Mohon isi nama Driver / kurir (ex : abc )</span
        >
      </div>
    </div>
  </div>
  <div class="mb-3 row">
    <label for="email_addr" class="col-md-3 col-form-label">Email </label>
    <div class="col-md-6">
      <input
        type="email"
        formControlName="email"
        class="form-control"
        id="email_addr"
        name="email_addr"
        placeholder="Email "
        [ngClass]="{ 'is-invalid': submit && form.email.errors }"
      />
      <div *ngIf="submit && form.email.errors" class="invalid-feedback">
        <span *ngIf="form.email.errors.required">Mohon isi alamat email</span>
      </div>
    </div>
  </div>
 
  <div class="mb-3 row" >
    <label for="password" class="col-md-3 col-form-label">Password </label>
    <div class="col-md-6">
        <input
          type="password"
          formControlName="password"
          class="form-control"
          id="password"
          name="password"
          placeholder="password "
          [ngClass]="{ 'is-invalid': submit && form.password.errors }"
        />
        <div *ngIf="submit && form.password.errors" class="invalid-feedback">
          <span *ngIf="form.password.errors.required">Mohon isi password</span>
        </div>
      </div>

    
  </div>


  
  <div class="btn-group float-end" role="group">
    <button
      type="button"
      class="btn btn-dark"
      (click)="batalForm()"
    >
      Batal
    </button>
    <button type="submit" class="btn btn-success">Simpan</button>
  </div>
</form>
