<div class="modal-header">
    <h5 class="modal-title mt-0">Filter Shipment</h5>
    <button type="button" class="btn-close" data-dismiss="modal" (click)="closeModal('dismiss')"><span aria-hidden="true"></span>
    </button>
</div>
<div class="modal-body">

    <div class="row">
        <div class="col-md-12">

            <div class="mb-1 row">
               
                <div class="col-md-4">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="formCheckCustomer" [(ngModel)]="filter.partner" [checked]="filter.partner">
                        <label class="form-check-label" for="formCheckCustomer">
                            Pelanggan
                        </label>
                    </div>
                    
                </div>
                <div class="col-md-8">
                    <div class="input-group">
                        <input type="text" class="form-control" placeholder="Kode Pengirim" id="partner_id" [(ngModel)]="filterData.partner_name" aria-describedby="partner_id" disabled="true" />
                        <button [disabled]="!filter.partner" class="btn btn-primary" type="button" id="btn_partner_id" (click)="openContact('CUSTOMER')"><i class="fas fa-users-cog"></i></button>
                    </div>
                </div>
            </div>

          
            
            <div class="mb-1 row">
               
                <div class="col-md-4">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="formCheckStatus" [(ngModel)]="filter.status" [checked]="filter.status">
                        <label class="form-check-label" for="formCheckStatus">
                            Status
                        </label>
                    </div>
                    
                </div>
                <div class="col-md-8">
                    <select class="form-select" [(ngModel)]="filterData.status_id" (ngModelChange)="onChangeFilter($event,'status')" id="moda_id" name="moda_id" [disabled]="!filter.status">
                        <option [value]="i?.status_id" *ngFor="let i of statusData">{{i.status_name}}</option>
                    </select>
                </div>
            </div>
            <div class="mb-1 row">
                <div class="col-md-4">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="formCheckPeriode" [(ngModel)]="filter.byPeriod" [checked]="filter.byPeriod">
                        <label class="form-check-label" for="formCheckPeriode">
                            Periode
                        </label>
                    </div>
                    
                </div>
                <div class="col-md-8">
                    <app-datepicker startDate="{{startDate}}" endDate="{{endDate}}"  (sendDate)="getDate($event)"></app-datepicker>
                </div>
            </div>
            
        </div>
    </div>
     

</div>
<div class="modal-footer">
  
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal('close')">Close</button>   
</div>