<div class="modal-header">
    <h5 class="modal-title mt-0"> Export Excel</h5>
    <button type="button" class="btn-close" data-dismiss="modal" (click)="closeModal()"><span aria-hidden="true"></span>
    </button>
</div>
<div class="card">
    <div class="card-body">
        <div class="row">
            <div class="col-md-12">
                <div class="row">
                    <label for="example-date-input" class="col-md-2 col-form-label">Periode</label>
                    <div class="col-md-10">
                        <div class="row">
                            <div class="col-md-6">
                                <input type="date" [(ngModel)]="startDate" class="form-control" id="startdate">
                            </div>
                            <div class="col-md-6">
                                <input type="date" [(ngModel)]="endDate" name="" class="form-control" id="enddate">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="exportData()">
        Export
    </button>
</div>