<div class="modal-header">
    <h5 class="modal-title mt-0">Tambah Info Pembayaran</h5>
    <button type="button" class="btn-close" data-dismiss="modal" (click)="closeModal('dismiss')"><span aria-hidden="true"></span>
    </button>
</div>
<div class="modal-body">
    <div class="row mt-3">
        <div class="col-lg-12 p-2" >
        
            <div class="row">
                <div class="col-md-12">
                    <div class="mb-1 row">
                        <label for="note" class="col-md-3 col-form-label">Informasi Pembayaran</label>
                        <div class="col-md-9">
                            <input type="text" class="form-control" [(ngModel)]="data.note" id="note" name="note"  />
                        </div>
                    </div>
                    <div class="mb-1 row">
                        <label for="no_rek1" class="col-sm-3 form-control-label">No Rekening </label>
                        <div class="col-sm-6">
                            <input  [(ngModel)]="data.no_rek1" class="form-control" id="no_rek1" name="no_rek1"
                                placeholder="Nomor Rekening Terdaftar">
                        </div>
                    </div>
                    <div class="mb-1 row">
                        <label for="bank_1" class="col-sm-3 form-control-label">BANK </label>
                        <div class="col-sm-6">
                            <input  [(ngModel)]="data.bank_1" class="form-control" id="bank_1" name="bank_1"
                                placeholder="Nama Bank">
                        </div>
                    </div>
                    <div class="mb-1 row">
                        <label for="bank_br_1" class="col-sm-3 form-control-label">Keterangan </label>
                        <div class="col-sm-6">
                            <input  [(ngModel)]="data.bank_br_1" class="form-control" id="bank_br_1" name="bank_br_1"
                                placeholder="Keterangan">
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
       
</div>
<div class="modal-footer">
  
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal('close')">Close</button>
    <button type="button" class="btn btn-success" data-dismiss="modal" (click)="save()">
        <i  *ngIf="loadingSave" class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
        <i  *ngIf="!loadingSave" class="fas fa-save font-size-16 align-middle me-2"></i>
         Simpan    </button>   
</div>