import { Component, Input, OnInit } from '@angular/core';
import { Toaster } from 'ngx-toast-notifications';
import { ApiService } from 'src/app/services/api.service';
import { GlobalConstants, popuppPrint } from 'src/app/services/global';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalPrintComponent } from 'src/app/modals/modal-print/modal-print.component';
import { ModalFilterComponent } from 'src/app/modals/modal-filter/modal-filter.component';
import { environment } from 'src/environments/environment';
import { Lightbox } from 'ngx-lightbox';
import { Router } from '@angular/router';
@Component({
  selector: 'app-shipment',
  templateUrl: './shipment.component.html',
  styleUrls: ['./shipment.component.scss']
})
export class ShipmentComponent implements OnInit {
  @Input() queryData:any;
  imgUrl: any = environment.publicUrl + "/files/uploads/";
  

  global={
    ...GlobalConstants.x
  }
  pgn={
    totalItems:0,
    itemsPerPage:0,
    limit:this.global.limitData,
    currentPage:1,
    prevPage:0,
    term:null
  };
  dataList:any;
  isRenameDoc: false;
  awbm:string="";
  awbItem:any;
  constructor(
    private apiService:ApiService,
    private toast:Toaster,
    private modal:NgbModal,
    private lightbox: Lightbox,
    private router: Router,
  ) { 
    this.pgn={
      totalItems:0,
      itemsPerPage:0,
      limit:this.global.limitData,
      currentPage:1,
      prevPage:0,
      term:null
    };
  }
  ngOnInit(): void {
    const currentUser = JSON.parse(localStorage.currentUser);
    this.isRenameDoc = currentUser?.is_user_manager || false;
    this.loadPage(1);
  }
  getFilter(){
    const modalRef = this.modal.open(ModalFilterComponent, {
      // scrollable: true,
      size:'xl'
    }); 
    modalRef.componentInstance.fromParent ={
      
    };
    modalRef.result.then((result) => {
    }, (reason) => {
    });
  }
  openImage(data: any): void {
    // open lightbox

    let Albums = [];

    if (data && data.pic1)
      Albums.push({
        src: `${this.imgUrl}${data?.pic1}`,
        caption: `Photo 1 - ${data?.description}`,
        thumb: `${this.imgUrl}${data?.pic1}`,
      });
    if (data && data.pic2)
      Albums.push({
        src: `${this.imgUrl}${data?.pic2}`,
        caption: `Photo 2 - ${data?.description}`,
        thumb: `${this.imgUrl}${data?.pic2}`,
      });
    if (data && data.pic3)
      Albums.push({
        src: `${this.imgUrl}${data?.pic3}`,
        caption: `Photo 3 - ${data?.description}`,
        thumb: `${this.imgUrl}${data?.pic3}`,
      });

    this.lightbox.open(Albums, 0, {
      wrapAround: true,
      showImageNumberLabel: true,
    });
  }
  loadPage(page: any) {
    if (page !== this.pgn.prevPage) {
      this.pgn.prevPage = page;
      this.pgn.currentPage=page;
      this.getData();
    }
  }
  showDataDetail(i:any){
    if (!this.dataList[i]['showDetail']){
      this.dataList[i]['showDetail']=true;
    }else{
      this.dataList[i]['showDetail']=false;
    }
  }

  openPrintModal(typePrint:string,id:string){
    const modalRef = this.modal.open(ModalPrintComponent, {
      // scrollable: true,
      size:'xl',
    }); 
    modalRef.componentInstance.fromParent ={
      format:typePrint,
      id,
      report:'RESI'
    };
    modalRef.result.then((result) => {
    }, (reason) => {
      console.log(reason);
    });
  }
  async getData(){
    try {
      const res: any  = await this.apiService.getQueryShipment(this.pgn.currentPage,this.pgn.limit,this.pgn.term,this.queryData.type,this.queryData.filter);
      if(res && res.success==true){
        this.dataList = res.data;
        this.pgn.totalItems=res.total;
        this.pgn.itemsPerPage=res.limit;
        // this.cu=res.current;
       
      }
      } catch (error) {
        this.toast.open({type:'danger',text:error})
      }
  }

  openModalDoc(ctx, item:any){
    this.awbm = item.shipment_awbm;
    this.awbItem = item;
    this.modal.open(ctx);
  }

  async updateAwbm(){
   try {
    await this.apiService.SHIPMENT.updateShipmentAwbm(this.awbItem.shipment_awb, { shipment_awbm: this.awbm });
    this.getData();
    this.modal.dismissAll();
    this.toast.open({type:'success',text:"Awb Berhasil di update"});
   } catch (error) {
    console.log(error);
    this.toast.open({type:'danger',text:"Awb Gagal di update"});
   }
  }

  print(id:string){
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/print/print-barcode/${id}`])
    );
    popuppPrint(url,"Manifest", 500, 700)
  }
}
