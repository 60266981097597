<div class="card">
	 
	<div class="card-body">

		 
		<div leaflet style="height: 480px;"
			 [leafletOptions]="options"
			 [leafletLayers]="markers">
		</div>

	</div>

</div>




