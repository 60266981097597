         <div class="mb-3 row">
           <div class="col-sm-8">
            <div class="input-group mb-3">
              <div class="ng-autocomplete" class="form-control"  >
                <ng-autocomplete
                      [data]="hubdata" 
                      [searchKeyword]="keyword" 
                      (selected)='selectEvent($event)'
                      (inputChanged)='onChangeSearch($event)' 
                      (inputFocused)='onFocused($event)' 
                      [itemTemplate]="itemTemplateStatic" 
                      (inputCleared)="searchCleared()"
                      [notFoundTemplate]="notFoundTemplate" 
                      historyListMaxNumber="3"
                      historyIdentifier="countriesReactiveList"
                      [historyHeading]="historyHeading"
                      [debounceTime]="600" 
                      [isLoading]="isLoadingResult" 
                      [minQueryLength]="2"
                      [(ngModel)]="modelName"
                      placeHolder="Cari nama kota">
              </ng-autocomplete>
                <ng-template #itemTemplateStatic let-item>
                  <a [innerHTML]="item.name"></a>
                </ng-template>
                <ng-template #notFoundTemplate let-notFound>
                  <div [innerHTML]="notFound"></div>
                </ng-template>
              </div>
              <button class="input-group-button btn btn-primary btn-group-sm"><i class="fa fa-search"></i></button>
            </div>
           </div>
           <label  class="col-md-3 align-items-center justify-content-center">{{dataName}}</label>
        </div>
