import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Toaster } from 'ngx-toast-notifications';
 
@Component({
  selector: 'app-modal-harga',
  templateUrl: './modal-harga.component.html',
  styleUrls: ['./modal-harga.component.scss']
})
export class ModalHargaComponent implements OnInit {

  @Input() params:any;
  customerId:string;
  constructor(
    public activeModal:NgbActiveModal,
    private toast:Toaster
  ) { }

  ngOnInit(): void {
    console.log(this.params)
    this.customerId=this.params.partner_id;
    
  }
  closeModal(sendData) {
    console.log(sendData);
    this.activeModal.close({
   
      data:sendData
    });
  }


}
