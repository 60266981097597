import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Lightbox } from 'ngx-lightbox';
import { Toaster } from 'ngx-toast-notifications';
import { ApiService } from 'src/app/services/api.service';
import { GlobalConstants } from 'src/app/services/global';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-modal-tracing',
  templateUrl: './modal-tracing.component.html',
  styleUrls: ['./modal-tracing.component.scss']
})
export class ModalTracingComponent implements OnInit {
  @Input() fromParent;
  imgUrl: any = environment.publicUrl + "/files/uploads/";
  dataDetail:any;
  constructor( public activeModal:NgbActiveModal,
    private apiService:ApiService,
    private toast:Toaster,
    private lightbox: Lightbox
    ) { }

  ngOnInit(): void {
    this.getData();
  }

  async getData(){
    const res:any=await this.apiService.getShipmentByShipmentAwbM(this.fromParent);
    console.log(res);
    if (res && res.success){
      this.dataDetail=res.data;
    }else{
      this.toast.open({type:'danger',text:'Data tidak ada'})
    }
  }
  openImage(data: any): void {
    // open lightbox

    let Albums = [];

    if (data && data.pic1)
      Albums.push({
        src: `${this.imgUrl}${data?.pic1}`,
        caption: `Photo 1 - ${data?.description}`,
        thumb: `${this.imgUrl}${data?.pic1}`,
      });
    if (data && data.pic2)
      Albums.push({
        src: `${this.imgUrl}${data?.pic2}`,
        caption: `Photo 2 - ${data?.description}`,
        thumb: `${this.imgUrl}${data?.pic2}`,
      });
    if (data && data.pic3)
      Albums.push({
        src: `${this.imgUrl}${data?.pic3}`,
        caption: `Photo 3 - ${data?.description}`,
        thumb: `${this.imgUrl}${data?.pic3}`,
      });

    this.lightbox.open(Albums, 0, {
      wrapAround: true,
      showImageNumberLabel: true,
    });
  }
  closeModal(sendData) {

    this.activeModal.close({
       
      data:sendData
    });
  }
}
