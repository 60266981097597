import { Component, Input, OnInit } from '@angular/core';
import { Toaster } from 'ngx-toast-notifications';
import { ApiService } from 'src/app/services/api.service';
import { GlobalConstants } from 'src/app/services/global';

@Component({
  selector: 'app-header-print',
  templateUrl: './header-print.component.html',
  styleUrls: ['./header-print.component.scss']
})
export class HeaderPrintComponent implements OnInit {
  global: any = {
    ...GlobalConstants.x
  }
  data:any;
  @Input('company-id') id: string;
  constructor(
    private apiService: ApiService,
    private toast: Toaster
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.getData();
    }, 300);
  }

  async getData(){
    try {
      const res: any  = await this.apiService.COMPANY.branchAll(1, 20, this.id); 
      this.data = res.data[0];
      
    } catch (error) {
      this.toast.open({type:'danger',text:error})
    }
  }

}
