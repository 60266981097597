import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Toaster } from "ngx-toast-notifications";
import { concat, of, Subject } from "rxjs";
import { distinctUntilChanged, switchMap, tap } from "rxjs/operators";
import { ModalHubComponent } from "src/app/modals/modal-hub/modal-hub.component";
import { ApiService } from "src/app/services/api.service";
import { GlobalConstants } from "src/app/services/global";

@Component({
  selector: 'app-master-contact',
  templateUrl: './master-contact.component.html',
  styleUrls: ['./master-contact.component.scss']
})
export class MasterContactComponent implements OnInit {
  @Input() isEdit: boolean;
  @Input() customerData: any;
  @Output() klikYes: EventEmitter<any> = new EventEmitter();
  validateForm!: FormGroup;
  submit: boolean = false;
  global = {...GlobalConstants.x};
  keyword = 'name';
  pgn={
    totalItems:0,
    itemsPerPage:0,
    limit:this.global.limitData,
    currentPage:1,
    prevPage:0,
    term:null
  };
  constructor(
    private apiService: ApiService,
    private toast: Toaster,
    private fb: FormBuilder,
    private modal:NgbModal
  ) {
    this.validateForm = this.fb.group({
      contact_id: [{ value: null, disabled: true }, [Validators.required]],
      name: [null, [Validators.required]],
      contact_reff: [null,[Validators.required]],
     
      phone1: [null],
      address1: [null, [Validators.required]],
      city_code: [{ value: null, disabled: true }, [Validators.required]],
      city_name: [{ value: null, disabled: true }],

    });
  }
  ngOnInit(): void {
    console.log(this.isEdit)
    if (this.isEdit==true) {
      
      this.fillFormData(this.customerData);
    }
  }
  fillFormData(data: any) {
    this.validateForm.controls["contact_id"].setValue(data.contact_id);
    this.validateForm.controls["name"].setValue(data.name);
    this.validateForm.controls["contact_reff"].setValue(data.contact_reff);
    this.validateForm.controls["phone1"].setValue(data.phone1);
    this.validateForm.controls["address1"].setValue(data.address1);
    this.validateForm.controls["city_code"].setValue(data.city_code);
    this.validateForm.controls["city_name"].setValue(data.city_name);
  }
  receiveHubData(item){
  }
  get form() {
    return this.validateForm.controls;
  }
  openModalHub(){
    const modalRef=this.modal.open(ModalHubComponent,{
      scrollable:true,
      size:'lg',
      keyboard:false,
      backdrop:'static'
    });
    modalRef.result.then((r)=>{
        if (r){
          this.validateForm.controls["city_code"].setValue(r.hub_id);
          this.validateForm.controls["city_name"].setValue(r.hub_name);
        }
    },(x)=>{
    })
  }
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
  batalForm(){
    this.klikYes.emit({s:false})

  }
  async submitForm() {
    this.submit = true;
    let prm ={
      ...this.validateForm.value,
      city_code:this.validateForm.controls['city_code'].value,
      city_name:this.validateForm.controls['city_name'].value
        }
    // if (this.validateForm.invalid) {
    //   return this.toast.open({ type: "danger", text: "Mohon isi field yang di perlukan" });
    // }
    if (this.isEdit==true){
        const res:any = await this.apiService.updateContact(prm,'other',this.validateForm.controls['contact_id'].value);
        if (res.success){
          
          this.toast.open({ type: "success", text: "Contact berhasil di update" });
          this.klikYes.emit({s:true})
          
        }else{
          return this.toast.open({ type: "danger", text: res.error });
        }
    }else{
      const res:any = await this.apiService.saveContact(prm,'other');
        if (res.success){
          this.toast.open({ type: "success", text: "Contact berhasil di simpan" });
          this.klikYes.emit({s:true})
        }else{
          return this.toast.open({ type: "danger", text: res.error });
        }
    }
      

  }

}
