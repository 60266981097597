import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { ProvinceService } from 'src/app/services/master/province.service';

@Component({
  selector: 'app-grid-provinsi',
  templateUrl: './grid-provinsi.component.html',
  styleUrls: ['./grid-provinsi.component.scss']
})
export class GridProvinsiComponent implements OnInit {
  datalist:any;
  page:any=1;
  limit:any=5;
  term:any='';
  total:any;
  totalPage:any;
  constructor(private provinceService: ProvinceService, private dataService: DataService) { }

  ngOnInit(): void {
    this.getUom({
      pg: this.page,
      limit: this.limit,
    });
  }

  async getUom(item: any) {
    const res: any = await this.provinceService.get(item);
    this.datalist = res.data;
    this.total = res.total;
    this.totalPage = res.pages;
  }

  async search(){
    this.page = 1;
    this.getUom({
      pg: this.page,
      limit: this.limit,
      term: this.term
    });
  }

  async loadPage(ev:any){
    this.page = ev;
    this.getUom({
      pg: this.page,
      limit: this.limit,
    });
  }

  pilih(item){
    this.dataService.provinsi.next(item);
  }

}
