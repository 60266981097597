import { Component, OnInit } from '@angular/core';
import { icon, latLng, Layer, marker, tileLayer } from 'leaflet';
import { Toaster } from 'ngx-toast-notifications';
import { DashboardService } from 'src/app/services/dashboard.service';
import { EventService } from 'src/app/services/event.service';
const globalDate = new Date();
@Component({
  selector: 'app-map-shipment',
  templateUrl: './map-shipment.component.html',
  styleUrls: ['./map-shipment.component.scss']
})
export class MapShipmentComponent implements OnInit {
  dataShipment: any;
  isLoadingData=false;
   typeActive:any;
   startDate: any = new Date(globalDate.getFullYear(), globalDate.getMonth(), 1);
  endDate: any = new Date(
    globalDate.getFullYear(),
    globalDate.getMonth() + 1,
    0
  );
  constructor(private dashboardService: DashboardService, private toaster: Toaster,
    private eventService: EventService,
    ) {
      this.eventService.periodeData.subscribe((data)=>{
        console.log(data);
        this.typeActive = data.typeActive;
        this.startDate = data.startDate;
        this.endDate = data.endDate;
        this.getData();
      });
    }


  ngOnInit() {
    this.getData();
  }

  // Open Street Map definitions
	LAYER_OSM = tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, attribution: 'Open Street Map' });

	// Values to bind to Leaflet Directive
	options = {
		layers: [ this.LAYER_OSM ],
		zoom: 5,
		center: latLng(-0.813,117.620)
	};

	markers: Layer[] = [];

	addMarker(data?:any) {
		// const newMarker = marker(
		// 	[ -6.2554 + 0.1 * (Math.random() - 0.5), 106.5938 + 0.1 * (Math.random() - 0.5) ],
		// 	{
		// 		icon: icon({
		// 			iconSize: [ 25, 41 ],
		// 			iconAnchor: [ 13, 41 ],
		// 			iconUrl: 'assets/images/logo/logo_profile_white.png',
		// 			iconRetinaUrl: 'assets/leaflet/marker-icon-2x.png',
		// 			shadowUrl: 'assets/leaflet/marker-shadow.png'
		// 		})
		// 	}
		// );
    const icn = {
      icon: icon({
        iconSize: [ 25, 41 ],
        iconAnchor: [ 13, 41 ],
        iconUrl: 'assets/images/logo/logo_profile_white.png',
        iconRetinaUrl: 'assets/leaflet/marker-icon-2x.png',
        shadowUrl: 'assets/leaflet/marker-shadow.png'
      })
    };

    for (const mm of data) {
   
     
      const newMarker= marker(latLng(mm.latitude,mm.longitude), {
        icon: icon({
          iconSize: [ 25, 41 ],
          iconAnchor: [ 13, 41 ],
          iconUrl: 'assets/images/logo/logo_profile_white.png',
          shadowUrl: 'assets/marker-shadow.png',
          tooltipAnchor: [ 0, -41 ]
        }),
        riseOnHover:true
      }).bindTooltip(`${mm.province_name}=${mm.ttl}`, {
        permanent: true,
        opacity: 1,
        direction: 'top'
      })
    //   const newMarker = marker(
		// 	[mm.latitude,mm.longitude ],
		// 	{
		// 		...icn
		// 	}
		// );
    this.markers.push(newMarker);
    }

		// this.markers.push(newMarker);
	}

  async getData() {
    console.log('----');
    this.isLoadingData=true;
    try{
      const res: any = await this.dashboardService.getDashboardShipmentByProvince(
        this.startDate,
        this.endDate,
        this.typeActive
      );
      if (res.success) {
        console.log(res);
      this.dataShipment =res.data;
      this.addMarker(this.dataShipment)
    } else {
      this.toaster.open({ type: "danger", text: "Error" });
    }
    
    } catch (err){
      this.isLoadingData=false;
      console.log(err)
    }
    
    
    
  }

	removeMarker() {
		this.markers.pop();
	}

}
 