<form
  class="form-data"
  [formGroup]="validateForm"
  (ngSubmit)="submitForm()">
    <div class="form row col-md-12">
        <div class="mb-3 row">
            <label for="company_id" class="col-md-2 col-form-label">Kode Perusahaan</label>
            <div class="col-md-4">
              <input
                class="form-control"
                type="text"
                id="company_id"
                formControlName="company_id"
                placeholder="Otomatis"
              />
            </div>
          </div>

        <div class="mb-1 row">
            <label for="website" class="col-md-2 col-form-label">Kode Kota</label>
            <div class="col-md-4">
                <div class="input-group">
                    <input type="text"   placeholder="Kode Kota Cabang" class="form-control" id="city_code" formControlName="city_code" >
                    <button type="button" class="btn btn-primary" (click)="openModalHub()" >
                        <i class="bx bx-search-alt"></i>
                    </button>
                </div>
            </div>
            <div class="col-md-6">
                    <input type="text"   placeholder="Nama Kota" class="form-control" id="city_code" formControlName="city_name" >
                    <div *ngIf="submit && form.city_code.errors" class="invalid-feedback">
                        <span *ngIf="form.city_code.errors.required">Mohon isi kode hub</span>
                      </div>
            </div>

        </div>

        <div class="mb-1 row">
            <label for="nama" class="col-md-2 col-form-label">Nama Cabang</label>
            <div class="col-md-6">
                <input class="form-control" placeholder="Nama Cabang" type="text" id="compay_name" formControlName="company_name">
            </div>
            <div class="col-md-4">
                <input class="form-control" placeholder="Brand" type="text" id="alias" formControlName="alias">
            </div>
        </div>
        
        <div class="mb-1 row">
            <label for="brand" class="col-md-2 col-form-label">Alamat</label>
            <div class="col-md-10">
                <textarea [rows]="3" name="alamat" class="form-control" placeholder="Alamat Cabang" id="" cols="30" rows="2" formControlName="address1"></textarea>
            </div>
        </div>
        <div class="mb-1 row">
            <label for="brand" class="col-md-2 col-form-label">PIC</label>
            <div class="col-md-10">
                <input class="form-control" placeholder="Pic Cabang" type="text" id="pic" formControlName="pic">
            </div>
        </div>
        <div class="mb-1 row">
            <label for="brand" class="col-md-2 col-form-label">Telepon1</label>
            <div class="col-md-10">
                <input class="form-control" placeholder="Telepon1" type="text" id="telepon1" formControlName="phone1">
            </div>
        </div>
        <div class="mb-1 row">
            <label for="brand" class="col-md-2 col-form-label">Telepon2</label>
            <div class="col-md-10">
                <input class="form-control" placeholder="Telepon2" type="text" id="telepon2" formControlName="phone2">
            </div>
        </div>
        <div class="mb-1 row">
            <label for="brand" class="col-md-2 col-form-label">Alamat Email</label>
            <div class="col-md-10">
                <input class="form-control" placeholder="Alamat email" type="text" id="email"  formControlName="email">
            </div>
        </div>
        <div class="mb-1 row">
            <label for="brand" class="col-md-2 col-form-label">Nama Invoice</label>
            <div class="col-md-10">
                <input class="form-control" placeholder="Nama TTD Di invoice" type="text" id="invoice_approve"  formControlName="invoice_approve">
            </div>
        </div>
        <div class="btn-group float-end" role="group">
            <button type="button" class="btn btn-dark" *ngIf="!isEdit" (click)="batalForm()">Batal</button>
            <button type="submit" class="btn btn-success">Simpan</button>
          </div>
    </div>
</form>